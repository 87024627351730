import React from "react"

import Layout from "../components/layout"

// Texas Housers Tenant Resources - 

const IndexPage = () => (
  <Layout>
    <section className="section has-background-light">
      <div className="container">
        <h3 className="title is-family-secondary">Get Help</h3>
        <div className="content">
          <p>If you are facing eviction, here is some information that may be helpful to you.</p>
          <ul className="listing">
            <li>
              <h4>Stop TX Eviction Guide &amp; Find Legal Assistance</h4>
              <p>
                Use this <a href="https://stoptxeviction.org/app/" target="_blank" rel="noopener noreferrer">Stop TX Eviction guide</a> to learn more about your options and apply for legal aid in your area.  <a href="https://stoptxeviction.org/es/app/" target="_blank" rel="noopener noreferrer">Página en Español</a>
              </p>
              <table>
                <thead>
                  <tr>
                    <th scope="col">Legal Provider</th>
                    <th scope="col">Who Can Qualify</th>
                    <th scope="col">How to Apply</th>
                    <th scope="col">Website</th>
                    <th scope="col">Phone</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Houston Volunteer Lawyers</th>
                    <td>
                      <p>
                        Any immigrant status.
                      </p>
                      <p>
                        (Cualquiera que sea su estatus migratorio.)
                      </p>
                    </td>
                    <td>
                      <p>
                        Apply <a href="https://www.makejusticehappen.org/node/24/how-get-help" target="_blank" rel="noopener noreferrer">online</a>.  If you cannot, please call.
                      </p>
                      <p>
                        (Aplica <a href="https://www.makejusticehappen.org/node/24/how-get-help" target="_blank" rel="noopener noreferrer">online</a>. Si no puede, llame.)
                      </p>
                    </td>
                    <td>
                      <a
                        href="https://www.makejusticehappen.org/node/24/how-get-help"
                        target="_blank"
                        rel="noopener noreferrer"
                      >makejusticehappen.org</a>
                    </td>
                    <td>(713) 228-0735</td>
                  </tr>
                  <tr>
                    <th scope="row">Lone Star Legal Aid</th>
                    <td>
                      <p>
                        Cannot assist undocumented persons who are not crime victims.
                      </p>
                      <p>
                        (No puede ayudar personas indocumentadas que no son victimas de delitos.)
                      </p>
                    </td>
                    <td>
                      <p>
                        Apply online at <a href="https://stoptxeviction.org/" target="_blank" rel="noopener noreferrer">StopTXEviction.org</a>.  If you cannot, please call.
                      </p>
                      <p>
                        (Aplica online en <a href="https://stoptxeviction.org/" target="_blank" rel="noopener noreferrer">StopTXEviction.org</a>. Si no puede, llame.)
                      </p>
                    </td>
                    <td>
                      <a
                        href="https://lonestarlegal.blog/covid-19-eviction-resources/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >lonestarlegal.org</a>
                    </td>
                    <td>(713) 652-0077</td>
                  </tr>
                  <tr>
                    <th scope="row">South Texas College of Law Clinic</th>
                    <td>
                      <p>
                        Any immigrant status.
                      </p>
                      <p>
                        (Cualquiera que sea su estatus migratorio.)
                      </p>
                    </td>
                    <td>
                      <p>
                        Apply <a href="http://www.stcl.edu/academics/legal-clinics/contact-us/" target="_blank" rel="noopener noreferrer">online</a>.  If you cannot, please call.
                      </p>
                      <p>
                        (Aplica <a href="http://www.stcl.edu/academics/legal-clinics/contact-us/" target="_blank" rel="noopener noreferrer">online</a>. Si no puede, llame.)
                      </p>
                    </td>
                    <td>
                      <a
                        href="http://www.stcl.edu/academics/legal-clinics/contact-us/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >stcl.edu/academics/legal-clinics/</a>
                    </td>
                    <td>(832) 554-6544</td>
                  </tr>
                  <tr>
                    <th scope="row">Earl Carl Institute (TSU Thurgood Marshall School of Law)</th>
                    <td>
                      <p>
                        Cannot assist undocumented persons.
                      </p>
                      <p>
                        (No puede ayudar personas indocumentadas.)
                      </p>
                    </td>
                    <td>
                      <p>
                        Apply <a href="http://www.tsulaw.edu/centers/ECI/apply_for_legal_services.html" target="_blank" rel="noopener noreferrer">online</a>.  If you cannot, please call.
                      </p>
                      <p>
                        (Aplica <a href="http://www.tsulaw.edu/centers/ECI/apply_for_legal_services.html" target="_blank" rel="noopener noreferrer">online</a>. Si no puede, llame.)
                      </p>
                    </td>
                    <td>
                      <a
                        href="http://www.tsulaw.edu/centers/ECI/apply_for_legal_services.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >tsulaw.edu/centers/ECI/</a>
                    </td>
                    <td>(713) 313-7275</td>
                  </tr>
                  <tr>
                    <th scope="row">University of Houston Clinical Programs</th>
                    <td>
                      <p>
                        Any immigrant status.
                      </p>
                      <p>
                        (Cualquiera que sea su estatus migratorio.)
                      </p>
                    </td>
                    <td>
                      <p>
                        Please call to apply.
                      </p>
                      <p>
                        (Llame para aplicar.)
                      </p>
                    </td>
                    <td>
                      <a
                        href="https://texaslawhelp.org/directory/legal-resource/university-houston-clinical-legal-programs"
                        target="_blank"
                        rel="noopener noreferrer"
                      >About at TexasLawHelp.org</a>
                    </td>
                    <td>(713) 743-2094</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              <h4>Find local assistance</h4>
              <ul>
                <li>
                  Visit <a href="https://www.bakerripleyrenthelp.org/" target="_blank" rel="noopener noreferrer">BakerRipley’s Rental Assistance Page</a>
                </li>
                <li>
                  Visit <a href="https://www.bakerripley.org/services/utility-assistance-program" target="_blank" rel="noopener noreferrer">BakerRipley’s Utility Assistance Page</a>
                </li>
              </ul>
            </li>
            <li>
              <h4>Learn more about the CDC Order</h4>
              <p>
                The CDC Order stops the court from processing an eviction or writ of possession for not paying rent for any person who submits this declaration to their landlord and the court, if you have an active case. This declaration is valid from September 4, 2020 through December 31, 2020.              </p>
              <p>
                You must sign and deliver this <a href="https://texaslawhelp.org/sites/default/files/cdc_housing_declaration_9.3.20.pdf" target="_blank" rel="noopener noreferrer">Tenant Declaration</a> to your landlord.
                Be sure that all the information is true for your case and only submit if it is.
              </p>
              <p>Learn more here about the <a href="https://www.cdc.gov/coronavirus/2019-ncov/downloads/eviction-moratoria-order-faqs.pdf" target="_blank" rel="noopener noreferrer">order here</a>.</p>
            </li>
            <li>
              <h4>New Texas Eviction Diversion Program</h4>
              <p>
                The Texas Eviction Diversion Program (TEDP) is a new program that is being tested in Harris County and other areas. The Judge should offer this option to you and your landlord at your hearing. It is a <i>voluntary</i> program and <strong>both</strong> you and your landlord must agree to participate in it.
              </p>
              <p>
                If you have an active eviction case, even if it has been postponed with a CDC order, you are eligible to apply! The organization administering the program is the Gulf Coast Community Service Association. <a href="https://applytedp.info/" target="_blank" rel="noopener noreferrer">Apply here</a> or call (713) 554-0667.
              </p>
              <p>
                This program may provide up to six months of rental assistance for eligible tenants. See the requirements <a href="https://www.txcourts.gov/media/1449884/tedpbrochureenglish.pdf" target="_blank" rel="noopener noreferrer">in this document</a>.
              </p>
            </li>
            <li>
              <h4>Form a payment plan with your landlord</h4>
              <p>
                Trying to work with your landlord to form a payment plan is the best option to avoid being sued if you are behind on rent. 
              </p>
              <p>
                Feel free to use the templates to talk to your landlord about reaching an agreement. We have it in multiple languages:
              </p>
              <ul>
                <li>
                  <a href="/Payment-Plan-Template-English.pdf" target="_blank" rel="noopener noreferrer">English</a>
                </li>
                <li>
                  <a href="/Payment-Plan-Template-Spanish.pdf" target="_blank" rel="noopener noreferrer">Spanish</a>
                </li>
                <li>
                  <a href="/Payment-Plan-Template-Arabic.pdf" target="_blank" rel="noopener noreferrer">Arabic</a>
                </li>
                <li>
                  <a href="/Payment-Plan-Template-Vietnamese.pdf" target="_blank" rel="noopener noreferrer">Vietnamese</a>
                </li>
                <li>
                  <a href="/Payment-Plan-Template-Mandarin.pdf" target="_blank" rel="noopener noreferrer">Mandarin</a>
                </li>
              </ul>
            </li>
            <li>
              <h4>More resources</h4>
              <ul>
                <li>
                  <a href="https://lonestarlegal.blog/covid-19-eviction-resources/" target="_blank" rel="noopener noreferrer">
                    Check out this Self-Guide to Evictions document provided by the Texas Justice Court Training Center. 
                  </a>
                </li>
                <li>
                  Visit <a href="https://www.readyharris.org/Incidents/COVID-19-Resources-Recovery" target="_blank" rel="noopener noreferrer">Harris County’s COVID-19 Resources Page</a>
                </li>
                <li>
                  Visit <a href="https://houstonemergency.org/covid19/" target="_blank" rel="noopener noreferrer">City of Houston’s COVID-19 Resources Page</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
